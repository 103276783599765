/* eslint-disable @typescript-eslint/no-var-requires */
// Search
export const getSuggestedPlacesQuery = require('./search/getSuggestedPlaces.graphql');
export const getSuggestedPlaceDetailsQuery = require('./search/getSuggestedPlaceDetails.graphql');
export const searchPropertiesByGeolocationQuery = require('./search/searchPropertiesByGeolocation.graphql');
export const searchPropertiesByLocationQuery = require('./search/searchPropertiesByLocation.graphql');
export const searchPropertiesByDestinationQuery = require('./search/searchPropertiesByDestination.graphql');
export const searchPropertyQuery = require('./search/searchProperty.graphql');
export const getAvailableFiltersByGeolocationQuery = require('./search/getAvailableFiltersByGeolocation.graphql');
export const getAvailableFiltersByLocationQuery = require('./search/getAvailableFiltersByLocation.graphql');
export const getAvailableFiltersByDestinationQuery = require('./search/getAvailableFiltersByDestination.graphql');
export const getPropertyMediaByIdsQuery = require('./search/propertyMediaByIds.graphql');
export const searchGroupRatesByGeolocationQuery = require('./search/searchGroupRatesByGeolocation.graphql');
export const searchGroupRatesByLocationQuery = require('./search/searchGroupRatesByLocation.graphql');
export const searchGroupRatesByDestinationQuery = require('./search/searchGroupRatesByDestination.graphql');
export const propertyMediaFragment = require('./search/propertyMediaFragment.graphql');
export const propertyMediaGalleryFragment = require('./search/propertyMediaGalleryFragment.graphql');

// Rooming List
export const validateAssociationQuery = require('./roominglist/validateAssociation.graphql');
export const getEventListAssociateQuery = require('./roominglist/getEventListAssociate.graphql');
export const getEventsByMiniHotelQuery = require('./roominglist/getEventsByMiniHotel.graphql');
export const saveRoomingListEventMutation = require('./roominglist/saveRoomingListEvent.graphql');
export const getEventListPlannerQuery = require('./roominglist/getEventListPlanner.graphql');
export const removePlannersMutation = require('./roominglist/removePlanners.graphql');
export const getEventSummaryByIdForAssociateQuery = require('./roominglist/getEventSummaryByIdForAssociate.graphql');
export const getEventSummaryByIdForPlannerQuery = require('./roominglist/getEventSummaryByIdForPlanner.graphql');
export const getRoomingListSpreadsheetUploadLogsQuery = require('./roominglist/getRoomingListSpreadsheetUploadLogs.graphql');
export const notifyPlannerOnCreateEventMutation = require('./roominglist/notifyPlannerOnCreateEvent.graphql');
export const getPropertyDetailsQuery = require('./roominglist/getPropertyDetails.graphql');

// Reslink
export const checkSuperUser = require('./reslink/checkSuperUser.graphql');
export const getReslinkEventsForAssociateQuery = require('./reslink/getReslinkEventsForAssociate.graphql');
export const getReslinkEventByIdQuery = require('./reslink/getReslinkEventById.graphql');
export const getReslinkEventByIdForActiveEventQuery = require('./reslink/getReslinkEventByIdForActiveEvent.graphql');
export const getReslinkEventByIdForModifyEventQuery = require('./reslink/getReslinkEventByIdForModifyEvent.graphql');
export const getReslinkEventByIdForSendEmailQuery = require('./reslink/getReslinkEventByIdForSendEmail.graphql');
export const addHotelGroupQuery = require('./reslink/addHotelGroup.graphql');
export const addHotelCorporateQuery = require('./reslink/addHotelCorporate.graphql');
export const updateEventActiveStateMutation = require('./reslink/updateEventActiveState.graphql');
export const submitReslinkMutation = require('./reslink/submitReslink.graphql');
export const notifyPlannerOnReslinkCreateEventMutation = require('./reslink/notifyPlannerOnReslinkCreateEvent.graphql');

// RFP
export const getPropertiesByIdsQuery = require('./rfp/getPropertiesByIds.graphql');
export const searchGroupRatesByPropertyIdsQuery = require('./rfp/searchGroupRatesByPropertyIds.graphql');
export const getLookupsByTypeQuery = require('./rfp/getLookupsByType.graphql');
export const getCustomerInfoQuery = require('./rfp/getCustomerInfo.graphql');
export const submitRfpMutation = require('./rfp/submitRfp.graphql');
export const createOrderForGroupBookingUsingEmailLinkMutation = require('./rfp/createOrderForGroupBookingUsingEmailLink.graphql');

// Quick Group
export const getPropertyDetailsForHeaderQuery = require('./quickgroup/getPropertyDetailsForHeader.graphql');
export const getCustomerSavedPropertiesQuery = require('./quickgroup/getCustomerSavedProperties.graphql');
export const updateCustomerSavedPropertiesMutation = require('./quickgroup/updateCustomerSavedProperties.graphql');
export const getGroupAvailabilityByPropertyQuery = require('./quickgroup/getGroupAvailabilityByProperty.graphql');
export const getEventSpaceDetailsQuery = require('./quickgroup/getEventSpaceDetails.graphql');
export const getExcludedFeesAndTaxesQuery = require('./quickgroup/getExcludedFeesAndTaxes.graphql');
export const getPropertyAcceptedPaymentCardsQuery = require('./quickgroup/getPropertyAcceptedPaymentCards.graphql');
export const submitQuickGroupMutation = require('./quickgroup/submitQuickGroup.graphql');
export const getPropertyDetailsForConfirmationQuery = require('./quickgroup/getPropertyDetailsForConfirmation.graphql');

// DTT
// Search
export const searchPropertiesByGeolocationDttQuery = require('./search/dtt/searchPropertiesByGeolocation.graphql');
export const searchPropertiesByLocationDttQuery = require('./search/dtt/searchPropertiesByLocation.graphql');
export const searchPropertiesByDestinationDttQuery = require('./search/dtt/searchPropertiesByDestination.graphql');
export const getAvailableFiltersByGeolocationDttQuery = require('./search/dtt/getAvailableFiltersByGeolocation.graphql');
export const getAvailableFiltersByLocationDttQuery = require('./search/dtt/getAvailableFiltersByLocation.graphql');
export const getAvailableFiltersByDestinationDttQuery = require('./search/dtt/getAvailableFiltersByDestination.graphql');
export const searchPropertyDttQuery = require('./search/dtt/searchProperty.graphql');

// Rooming List
export const validateAssociationDttQuery = require('./roominglist/dtt/validateAssociation.graphql');
export const getEventListAssociateDttQuery = require('./roominglist/dtt/getEventListAssociate.graphql');
export const getEventsByMiniHotelDttQuery = require('./roominglist/dtt/getEventsByMiniHotel.graphql');
export const saveRoomingListEventMutationDtt = require('./roominglist/dtt/saveRoomingListEvent.graphql');
export const getEventSummaryByIdForAssociateDttQuery = require('./roominglist/dtt/getEventSummaryByIdForAssociate.graphql');
export const notifyPlannerOnCreateEventDttMutation = require('./roominglist/dtt/notifyPlannerOnCreateEvent.graphql');
export const getEventListPlannerDttQuery = require('./roominglist/dtt/getEventListPlanner.graphql');
export const getEventSummaryByIdForPlannerDttQuery = require('./roominglist/dtt/getEventSummaryByIdForPlanner.graphql');
export const getRoomingListSpreadsheetUploadLogsDttQuery = require('./roominglist/dtt/getRoomingListSpreadsheetUploadLogs.graphql');
export const removePlannersDttMutation = require('./roominglist/dtt/removePlanners.graphql');

// Reslink
export const checkSuperUserDttQuery = require('./reslink/dtt/checkSuperUser.graphql');
export const getReslinkEventsForAssociateDttQuery = require('./reslink/dtt/getReslinkEventsForAssociate.graphql');
export const getReslinkEventByIdDttQuery = require('./reslink/dtt/getReslinkEventById.graphql');
export const updateEventActiveStateDttMutation = require('./reslink/dtt/updateEventActiveState.graphql');
export const getReslinkEventByIdForActiveEventDttQuery = require('./reslink/dtt/getReslinkEventByIdForActiveEvent.graphql');
export const getReslinkEventByIdForModifyEventDttQuery = require('./reslink/dtt/getReslinkEventByIdForModifyEvent.graphql');
export const addHotelGroupDttQuery = require('./reslink/dtt/addHotelGroup.graphql');
export const submitReslinkDttMutation = require('./reslink/dtt/submitReslink.graphql');
export const getReslinkEventByIdForSendEmailDttQuery = require('./reslink/dtt/getReslinkEventByIdForSendEmail.graphql');
export const notifyPlannerOnReslinkCreateEventDttMutation = require('./reslink/dtt/notifyPlannerOnReslinkCreateEvent.graphql');

// RFP
export const submitRfpDttMutation = require('./rfp/dtt/submitRfp.graphql');
