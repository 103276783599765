import styled from 'styled-components';
import { toRem, baseVariables } from '@marriott/mi-ui-library';

const { color, font } = baseVariables;

export const StyledTypeAheadFlyout = styled.div`
  overflow: visible;
  width: 100%;
  position: relative;

  .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root'] .MuiAutocomplete-input:first-child {
    border-bottom: none;
    padding: ${toRem(14)} 0 ${toRem(13)} ${toRem(7)};
    font-size: ${toRem(16)};
    &::placeholder {
      color: ${color.neutral40};
      opacity: 1;
    }
    [dir='rtl'] & {
      padding-top: ${toRem(16)};
      padding-bottom: ${toRem(16)};
    }
  }

  .MuiFormLabel-root {
    transform: none;
    text-transform: unset;
    font-size: ${toRem(14)};
    color: ${color.neutral40};
    padding: 0 ${toRem(7)};
    background-color: ${color.base20};
    position: absolute;
    top: ${toRem(-8)};
    left: ${toRem(8)};
    font-weight: ${font.fontWeightRegular};
    font-family: ${font.fontSwiss};
    max-width: max-content;

    &.Mui-error {
      color: ${color.alert50};
    }

    &.Mui-focused {
      color: ${color.base10};
    }
  }

  .MuiOutlinedInput-notchedOutline {
    border-color: ${color.neutral40};
    legend {
      [dir='rtl'] & {
        width: 0;
      }
    }
  }
  .MuiAutocomplete-hasClearIcon {
    .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root'] {
      [dir='rtl'] & {
        padding-right: ${toRem(40)};
        padding-left: ${toRem(10)};
      }
      .MuiAutocomplete-endAdornment {
        [dir='rtl'] & {
          right: 10px;
          left: auto;
        }
      }
    }
  }

  .MuiOutlinedInput-root {
    &.Mui-focused {
      .MuiOutlinedInput-notchedOutline {
        border-color: ${color.base10};
        border-width: ${toRem(1)};
        box-shadow: 0 0 0 ${toRem(0.5)} inset;
      }

      &.Mui-error {
        .MuiOutlinedInput-notchedOutline {
          box-shadow: 0 0 0 ${toRem(0.5)} ${color.alert50} inset;
        }
      }
    }

    &.Mui-error {
      .MuiOutlinedInput-notchedOutline {
        border-color: ${color.alert50};
      }
    }
  }

  .MuiFormHelperText-root {
    &.Mui-error {
      color: ${color.alert50};
      margin-left: 0;
      font-family: ${font.fontSwiss};
      font-weight: ${font.fontWeightMedium};
    }
  }

  .MuiIconButton-root:hover {
    background: none;
  }

  .MuiInputLabel-outlined {
    &.MuiInputLabel-shrink {
      transform: none;
    }
  }

  .MuiAutocomplete-listbox {
    max-height: unset;
    padding: 0;
  }

  .MuiAutocomplete-paper {
    display: inherit;
    margin: 0;
    .t-font-m {
      margin: 0;
      font-size: ${toRem(14)};
    }
  }

  .MuiPaper-rounded {
    padding: ${toRem(16)} 0;
    border-radius: 0 0 ${toRem(14)} ${toRem(14)};
    border: ${toRem(1)} solid ${color.neutral30};
    box-shadow: 0 ${toRem(2)} ${toRem(8)} 0 rgba(0, 0, 0, 0.07);
  }

  .MuiAutocomplete-clearIndicator {
    .hide-icon,
    .show-icon,
    .MuiTouchRipple-root {
      display: none;
    }
  }

  .MuiFormControl-root .Mui-focused .MuiAutocomplete-clearIndicator .show-icon {
    display: block;
  }
`;
