/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  DefaultValue,
  SearchFacetsConstants,
  SearchFacetsList,
  SearchPointsEndPointArr,
  SearchRangesEndPointArr,
  SearchDistanceRangesEndPointArr,
  SearchSortFieldsDirection,
  descriptionFilterToDisplayOnPropertyCard,
  resortDescriptionFilterToDisplayOnPropertyCard,
  destinationDescriptionFilterToDisplayOnPropertyCard,
  LA_COUNTY_FEE_DESCRIPTION,
  RateNodeTypeToBeUsedInQuery,
  PointsNodeTypeToBeUsedInQuery,
  countryCodeForChina,
  payloadCountryCodeForChina,
  destinationValueForChina,
  SearchSortFieldForDISTANCE,
  RECOMMENDED_SORT,
  RECOMMENDED_OPTION,
  hardCodedAllLocale,
} from '../component/index.constant';
import config from '../lib/config';
import { enableBoostBrands, getDestinationHiddenFilterData, enableHotelList, updateQueryParams } from '../lib/helper';
import {
  CommonInputValueType,
  SearchFacetsType,
  SearchOptionsSubType,
  SearchOptionsType,
  SearchQueryOptionsType,
  SearchResultDataType,
  SearchStateExtendsType,
  SearchStateFilterObj,
  SearchStateStoreDataType,
} from './store.type';
import { MILESTOMETERS } from '../lib/application.constants';
import { LEISURE_REGION_PIDS_TO_SUPRESS } from '../../../../constants/lib/constants';

export enum SearchActions {
  setSearchResultData = 'action/resultData',
  setQuery = 'action/search',
  setFilter = 'action/filterState',
  setApplyFilter = 'action/applyFilter',
  updateSearchTerms = 'action/terms',
  changeCurrentView = 'action/view',
  setSearchResultError = 'action/updateError',
  //update other search query param like offset, taxes and limit along with search
  updateOtherSearchQueryParam = 'action/offset-limit-sort',
  updateTaxesAndAvailability = 'action/taxes-available',
  updatePoints = 'action/updatePoints',
  updateCurrency = 'action/currency',
  updateMedia = 'action/mediaTour',
  updateAmenities = 'action/amenities',
  updateActivities = 'action/activities',
  updateBrands = 'action/brands',
  updateTransportationTypes = 'action/transportationTypes',
  updateQueryAndOtherSearchQueryParam = 'action/updateQueryAndOtherSearchQueryParam',
}

export function setSearchQuery(
  state: SearchStateStoreDataType,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  payload: any
): SearchStateStoreDataType {
  return {
    ...state,
    [SearchFacetsConstants.search]: {
      ...state?.search,
      ...payload,
    },
  };
}

export function setSearchResultList(state: SearchResultDataType, payload: SearchResultDataType): SearchResultDataType {
  return { ...state, ...payload };
}

export function getSearchFilterInitialState(data: SearchStateStoreDataType): SearchStateExtendsType {
  // eslint-disable-next-line prefer-const
  let customFilterObj: SearchStateExtendsType = {};
  Object.values(SearchFacetsList).forEach((key: SearchFacetsList) => {
    if (data[key] !== undefined) {
      customFilterObj[key] = data[key];
    }
  });
  return customFilterObj;
}

export function getSelectedFilters(data: SearchStateStoreDataType): string {
  let selectedFilters = '';
  Object.values(SearchFacetsList).forEach((key: SearchFacetsList) => {
    if (key !== SearchFacetsList.price && key !== SearchFacetsList.distance && data[key] !== undefined) {
      selectedFilters += key + data[key];
    }
  });
  return selectedFilters;
}

export function getSearchFilterClearState(): SearchStateExtendsType {
  // eslint-disable-next-line prefer-const
  let customFilterObj: SearchStateExtendsType = {};
  Object.values(SearchFacetsList).forEach((key: SearchFacetsList) => {
    customFilterObj[key] = {};
  });
  return customFilterObj;
}

export function getSearchAppliedFilter(
  data: SearchStateExtendsType | null = {},
  currentState: SearchStateStoreDataType
): SearchFacetsType {
  // transform search response facets to search query facets
  // get all applied filter form context

  const terms: Array<CommonInputValueType> = [];
  const ranges: Array<CommonInputValueType> = [];

  let arr = Object.values(SearchFacetsList);
  if (currentState[SearchFacetsConstants.points]) {
    arr = arr.filter(key => key !== SearchFacetsList.price);
  } else {
    arr = arr.filter(key => key !== SearchFacetsList.points);
  }

  arr.forEach((key: SearchFacetsList) => {
    if (enableBoostBrands() === true && key === 'BRANDS') {
      terms.push({
        type: key,
        dimensions: [],
      });
    } else if (key !== SearchFacetsList.price && key !== SearchFacetsList.distance && key !== SearchFacetsList.points) {
      // to add terms filters
      const currKey = currentState?.[key] !== undefined ? Object.keys(currentState[key] ?? {}) : [];

      // add condition for countries facet for China
      if (
        (key === SearchFacetsList.countries &&
          Object.keys(data?.[key] ?? {})?.length > 0 &&
          Object.keys(data?.[key] ?? {})[0] === countryCodeForChina) ||
        (key === SearchFacetsList.countries &&
          currentState?.search?.destination &&
          currentState?.search?.destination?.toUpperCase() === destinationValueForChina)
      ) {
        terms.push({
          type: key,
          dimensions: payloadCountryCodeForChina?.split(/[\s,]+/),
        });
      } else {
        terms.push({
          type: key,
          dimensions: data?.[key] !== undefined ? Object.keys(data[key] ?? {}) : currKey,
        });
      }
    } else if (key === SearchFacetsList.price || key === SearchFacetsList.distance || key === SearchFacetsList.points) {
      // to add ranges filters
      const currVal = currentState?.[key] !== undefined ? Object.keys(currentState[key] ?? {}) : [];
      if (currentState[SearchFacetsConstants.points] && key !== SearchFacetsList.distance) {
        ranges.push({
          type: key,
          dimensions: data?.[key] !== undefined ? Object.keys(data[key] ?? {}) : currVal,
          endpoints: SearchPointsEndPointArr,
        });
      } else if (key === SearchFacetsList.distance) {
        ranges.push({
          type: key,
          dimensions: data?.[key] !== undefined ? Object.keys(data[key] ?? {}) : currVal,
          endpoints: SearchDistanceRangesEndPointArr,
        });
      } else {
        ranges.push({
          type: key,
          dimensions: data?.[key] !== undefined ? Object.keys(data[key] ?? {}) : currVal,
          endpoints: SearchRangesEndPointArr,
        });
      }
    }
  });
  return { [SearchFacetsConstants.terms]: terms, [SearchFacetsConstants.ranges]: ranges };
}

export const getInitialSearchSort = (
  latitude: any,
  enableTargetCall: string,
  currentLocale: string,
  segmentId: string,
  isStateSearch: boolean,
  destinationAddressPlaceId: string
) => {
  if (
    enableTargetCall?.toString() === 'true' &&
    currentLocale?.replace('_', '-') === hardCodedAllLocale.en &&
    segmentId === RECOMMENDED_SORT &&
    !isStateSearch
  ) {
    return {
      fields: [
        {
          field: RECOMMENDED_OPTION,
          direction: SearchSortFieldsDirection.desc,
        },
      ],
    };
  } else if (enableHotelList() === true) {
    return {
      fields: [
        {
          field: 'PROPERTY_NAME',
          direction: SearchSortFieldsDirection.asc,
        },
      ],
    };
  } else {
    return {
      fields:
        latitude && latitude !== ''
          ? [
              {
                field: 'DISTANCE',
                direction: SearchSortFieldsDirection.asc,
              },
            ]
          : LEISURE_REGION_PIDS_TO_SUPRESS?.includes(destinationAddressPlaceId)
          ? [
              {
                field: 'CITY',
                direction: SearchSortFieldsDirection.asc,
              },
            ]
          : SearchSortFieldForDISTANCE?.map((fieldVal: any) => {
              return {
                field: fieldVal,
                direction: SearchSortFieldsDirection.asc,
              };
            }),
    };
  }
};

export const getInitialSearchFacets = (points: boolean): SearchFacetsType => {
  return {
    [SearchFacetsConstants.terms]: [
      {
        type: SearchFacetsList.brand,
        dimensions: [],
      },
      {
        type: SearchFacetsList.amenities,
        dimensions: [],
      },
      {
        type: SearchFacetsList.propertyType,
        dimensions: [],
      },
      {
        type: SearchFacetsList.transportTypes,
        dimensions: [],
      },
      {
        type: SearchFacetsList.hotelType,
        dimensions: [],
      },
      {
        type: SearchFacetsList.cities,
        dimensions: [],
      },
      {
        type: SearchFacetsList.countries,
        dimensions: [],
      },
      {
        type: SearchFacetsList.meetingEvents,
        dimensions: [],
      },
    ],
    [SearchFacetsConstants.ranges]: [
      points
        ? {
            type: SearchFacetsList.points,
            dimensions: [],
            endpoints: SearchPointsEndPointArr,
          }
        : {
            type: SearchFacetsList.price,
            dimensions: [],
            endpoints: SearchRangesEndPointArr,
          },
      {
        type: SearchFacetsList.distance,
        dimensions: [],
        endpoints: SearchDistanceRangesEndPointArr,
      },
    ],
  };
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getInitialSearchObj = (sessionData: any): SearchOptionsType => {
  if (sessionData?.latitude) {
    return {
      options: sessionData?.options ?? undefined,
      latitude: sessionData?.latitude,
      longitude: sessionData?.longitude,
      facets: getInitialSearchFacets(sessionData?.usePoints ?? false),
    };
  }
  return {
    options: sessionData?.options ?? undefined,
    destination: sessionData?.keyword,
    facets: getInitialSearchFacets(sessionData?.usePoints ?? false),
  };
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getInitialSearchMockState = (
  sessionData: any,
  enableTargetCall: string,
  currentLocale: string,
  segmentId: string,
  isStateSearch: boolean
): SearchStateStoreDataType => {
  return {
    [SearchFacetsConstants.search]: getInitialSearchObj(sessionData), // get initial search obj from session on page load
    filter: [],
    [SearchFacetsConstants.includeTaxes]: sessionData?.showFullPrice,
    [SearchFacetsConstants.unavailableProperties]: !sessionData?.showAvailableHotels,
    [SearchFacetsConstants.limit]: parseInt(config.SHOP_RESULT_NO_OFFSET),
    [SearchFacetsConstants.offset]: 0,
    [SearchFacetsConstants.goToNextPage]: 0,
    [SearchFacetsConstants.points]: sessionData?.usePoints ?? false,
    currentCurrency: sessionData?.selectedCurrency ?? DefaultValue,
    currentConversionRate: [],
    currentAppliedCurrency: DefaultValue,
    [SearchFacetsConstants.sort]: getInitialSearchSort(
      sessionData?.latitude,
      enableTargetCall,
      currentLocale,
      segmentId,
      isStateSearch,
      sessionData?.destinationAddressPlaceId
    ),
    [SearchFacetsList.amenities]: sessionData?.amenities,
    [SearchFacetsList.activities]: sessionData?.activities,
    [SearchFacetsList.brand]: sessionData?.brands,
    [SearchFacetsList.transportTypes]: sessionData?.transportationTypes,
    [SearchFacetsList.propertyType]: sessionData?.hotelTypes,
    [SearchFacetsList.meetingEvents]: sessionData?.events,
    [SearchFacetsList.states]: sessionData?.states,
    [SearchFacetsList.cities]: sessionData?.cities,
    [SearchFacetsList.distance]: sessionData?.distance,
    [SearchFacetsList.price]: sessionData?.price,
    [SearchFacetsList.points]: sessionData?.points,
  };
};

/**
 * Function to update the QueryParams if the filter's are present in session
 */
export const updateQueryParamsFromSession = (filterSelectionData: any, headersData: any, filterApplied: boolean) => {
  const amenities =
    filterSelectionData && filterSelectionData?.amenities
      ? Object.values(filterSelectionData?.amenities as SearchStateFilterObj)
      : [];
  const transportationTypes =
    filterSelectionData && filterSelectionData?.transportationTypes
      ? Object.values(filterSelectionData?.transportationTypes as SearchStateFilterObj)
      : [];
  const brands =
    filterSelectionData && filterSelectionData?.brands
      ? Object.values(filterSelectionData?.brands as SearchStateFilterObj)
      : [];
  const hotelTypes =
    filterSelectionData && filterSelectionData?.hotelTypes
      ? Object.values(filterSelectionData?.hotelTypes as SearchStateFilterObj)
      : [];
  const activities =
    filterSelectionData && filterSelectionData?.activities
      ? Object.values(filterSelectionData?.activities as SearchStateFilterObj)
      : [];
  const events =
    filterSelectionData && filterSelectionData?.events
      ? Object.values(filterSelectionData?.events as SearchStateFilterObj)
      : [];
  const states =
    filterSelectionData && filterSelectionData?.states
      ? Object.values(filterSelectionData?.states as SearchStateFilterObj)
      : [];
  const cities =
    filterSelectionData && filterSelectionData?.cities
      ? Object.values(filterSelectionData?.cities as SearchStateFilterObj)
      : [];
  const distanceRangeIndex =
    filterSelectionData && filterSelectionData?.distance[0]
      ? [filterSelectionData?.distance[0]]
      : filterApplied
      ? [0]
      : [];
  const priceRangeIndex =
    filterSelectionData && filterSelectionData?.price[0] ? [filterSelectionData?.price[0]] : filterApplied ? [0] : [];
  const pointsRangeIndex =
    filterSelectionData && filterSelectionData?.points[0] ? [filterSelectionData?.points[0]] : filterApplied ? [0] : [];
  if (typeof window !== 'undefined' && filterSelectionData) {
    /**
     * Update the query params
     */
    updateQueryParams(
      {
        amenities,
        transportationTypes,
        brands,
        hotelTypes,
        activities,
        events,
        states,
        cities,
        distanceRangeIndex,
        priceRangeIndex,
        pointsRangeIndex,
      },
      [
        'amenities',
        'transportationTypes',
        'brands',
        'hotelTypes',
        'activities',
        'events',
        'states',
        'cities',
        'distanceRangeIndex',
        'priceRangeIndex',
        'pointsRangeIndex',
      ],
      headersData?.referer
    );
  }
};

// to construct search query from context
// used only to send the request to get the search result data

export const getSearchResultQuery = (
  state: SearchStateStoreDataType,
  isTaxesandFeesIncludedFromPopup?: boolean,
  showFullPriceInSession?: string,
  isFlexibleDateSearch?: boolean,
  custNumber?: string,
  distance?: number
): SearchQueryOptionsType => {
  const searchState = state[SearchFacetsConstants.search];
  /**
   * if we are getting distance less then 1000, i.e. in miles then we are converting it into meters
   */
  const dist = distance ? (distance < 1000 ? { distance: distance * MILESTOMETERS } : { distance: distance }) : {};

  const searchVal =
    enableHotelList() === false
      ? searchState[SearchFacetsConstants.destination] && !searchState[SearchFacetsConstants.latitude]
        ? {
            /**
             * flexible + destination query
             */
            [SearchFacetsConstants.destination]: searchState[SearchFacetsConstants.destination],
            ...dist,
          }
        : {
            /**
             * dated + geo location query param
             */
            [SearchFacetsConstants.latitude]: Number(searchState[SearchFacetsConstants.latitude]),
            [SearchFacetsConstants.longitude]: Number(searchState[SearchFacetsConstants.longitude]),
            ...dist,
          }
      : {};

  const optionsObj = searchState[SearchFacetsConstants.options];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const rateType: any = state[SearchFacetsConstants.points]
    ? [
        /**
         * to get the points with price
         */
        {
          type: 'CLUSTER',
          value: 'MRW', // hardcode the value to get the points on the search result
        },
        {
          type: 'STANDARD',
          value: '',
        },
        {
          type: 'CLUSTER',
          value: 'P17',
        },
      ]
    : optionsObj?.rateRequestTypes ?? [];

  const options: SearchOptionsSubType = isFlexibleDateSearch
    ? {
        customerId: custNumber,
        numberInParty: optionsObj?.numberInParty ? optionsObj?.numberInParty : 1,
        numberOfGuestRooms: optionsObj?.numberOfGuestRooms ? optionsObj?.numberOfGuestRooms : 1,
      }
    : {
        ...optionsObj,
        customerId: custNumber,
        quantity: optionsObj?.quantity ? optionsObj?.quantity : 1,
        numberInParty: optionsObj?.numberInParty ? optionsObj?.numberInParty : 1,
        [SearchFacetsConstants.includeTaxes]: isTaxesandFeesIncludedFromPopup
          ? state[SearchFacetsConstants.includeTaxes]
          : JSON.parse(showFullPriceInSession ?? ''),
        [SearchFacetsConstants.unavailableProperties]: state[SearchFacetsConstants.unavailableProperties],
        rateRequestTypes: rateType,
      };

  if (isFlexibleDateSearch && !searchState[SearchFacetsConstants.latitude]) {
    /** remove number of guest from destination query in order to match the result count */
    const isGuest = options?.numberOfGuestRooms && Number(options?.numberOfGuestRooms) > 1;
    if (!isGuest) {
      delete options?.numberOfGuestRooms;
    }
  }
  const searchObj: SearchOptionsType = {
    ...searchVal,
    [SearchFacetsConstants.options]: options,
    [SearchFacetsConstants.facets]:
      getSearchAppliedFilter({}, state) ?? getInitialSearchFacets(state[SearchFacetsConstants.points] ?? false),
  };

  // create a new object for boost brands scenario
  let searchObjForBoostBrands: SearchOptionsType;

  // create a new object for specifix hotels list scenario
  let searchObjForHotelList: SearchOptionsType;

  // create a new object for specifix hotels list and bootst brands combined scenario
  let searchObjForHotelListBoostBrands: SearchOptionsType;

  // conditon for hotellist=marsha and boost brand combined scenario
  if (enableHotelList() === true && enableBoostBrands() === true) {
    const params = new URLSearchParams(window.location.search);
    const hotelsList = params?.has('hotelList') ? params?.getAll('hotelList')?.toString()?.split(',') : [];
    const brandsList = params?.has('marriottBrands') ? params?.getAll('marriottBrands')?.toString()?.split(',') : [];

    searchObjForHotelListBoostBrands = {
      ...searchObj,
      [SearchFacetsConstants.ids]: hotelsList,
      [SearchFacetsConstants.promote]: { type: 'BRAND', values: brandsList },
    };

    return {
      /**
       * return required params for boost brands search UXL call
       */
      [SearchFacetsConstants.search]: searchObjForHotelListBoostBrands,
      [SearchFacetsConstants.limit]: state[SearchFacetsConstants.limit],
      [SearchFacetsConstants.offset]: state[SearchFacetsConstants.offset],
      [SearchFacetsConstants.sort]: state[SearchFacetsConstants.sort],
      [SearchFacetsConstants.filter]: searchState?.options?.includeMandatoryFees
        ? [
            descriptionFilterToDisplayOnPropertyCard,
            resortDescriptionFilterToDisplayOnPropertyCard,
            destinationDescriptionFilterToDisplayOnPropertyCard,
            LA_COUNTY_FEE_DESCRIPTION,
          ]
        : [descriptionFilterToDisplayOnPropertyCard],
      [SearchFacetsConstants.modes]: [RateNodeTypeToBeUsedInQuery, PointsNodeTypeToBeUsedInQuery],
    };
  } else if (enableHotelList() === true) {
    const params = new URLSearchParams(window.location.search);
    const hotelsList = params?.has('hotelList') ? params?.getAll('hotelList')?.toString()?.split(',') : [];

    searchObjForHotelList = {
      ...searchObj,
      [SearchFacetsConstants.ids]: hotelsList,
    };

    return {
      /**
       * return required params for search UXL call
       */
      [SearchFacetsConstants.search]: searchObjForHotelList,
      [SearchFacetsConstants.limit]: state[SearchFacetsConstants.limit],
      [SearchFacetsConstants.offset]: state[SearchFacetsConstants.offset],
      [SearchFacetsConstants.sort]: state[SearchFacetsConstants.sort],
      [SearchFacetsConstants.filter]: searchState?.options?.includeMandatoryFees
        ? [
            descriptionFilterToDisplayOnPropertyCard,
            resortDescriptionFilterToDisplayOnPropertyCard,
            destinationDescriptionFilterToDisplayOnPropertyCard,
            LA_COUNTY_FEE_DESCRIPTION,
          ]
        : [descriptionFilterToDisplayOnPropertyCard],
      [SearchFacetsConstants.modes]: [RateNodeTypeToBeUsedInQuery, PointsNodeTypeToBeUsedInQuery],
    };
  } else if (enableBoostBrands() === true) {
    const params = new URLSearchParams(window.location.search);
    const brandsList = params?.has('marriottBrands') ? params?.getAll('marriottBrands')?.toString()?.split(',') : [];

    searchObjForBoostBrands = {
      ...searchObj,
      [SearchFacetsConstants.promote]: { type: 'BRAND', values: brandsList },
    };

    return {
      /**
       * return required params for boost brands search UXL call
       */
      [SearchFacetsConstants.search]: searchObjForBoostBrands,
      [SearchFacetsConstants.limit]: state[SearchFacetsConstants.limit],
      [SearchFacetsConstants.offset]: state[SearchFacetsConstants.offset],
      [SearchFacetsConstants.filter]: searchState?.options?.includeMandatoryFees
        ? [
            descriptionFilterToDisplayOnPropertyCard,
            resortDescriptionFilterToDisplayOnPropertyCard,
            destinationDescriptionFilterToDisplayOnPropertyCard,
            LA_COUNTY_FEE_DESCRIPTION,
          ]
        : [descriptionFilterToDisplayOnPropertyCard],
      [SearchFacetsConstants.modes]: [RateNodeTypeToBeUsedInQuery, PointsNodeTypeToBeUsedInQuery],
    };
  } else {
    return {
      /**
       * return required params for search UXL call
       */
      [SearchFacetsConstants.search]: searchObj,
      [SearchFacetsConstants.limit]: state[SearchFacetsConstants.limit],
      [SearchFacetsConstants.offset]: state[SearchFacetsConstants.offset],
      [SearchFacetsConstants.sort]: state[SearchFacetsConstants.sort],
      [SearchFacetsConstants.filter]: searchState?.options?.includeMandatoryFees
        ? [
            descriptionFilterToDisplayOnPropertyCard,
            resortDescriptionFilterToDisplayOnPropertyCard,
            destinationDescriptionFilterToDisplayOnPropertyCard,
            LA_COUNTY_FEE_DESCRIPTION,
          ]
        : [descriptionFilterToDisplayOnPropertyCard],
      [SearchFacetsConstants.modes]: [RateNodeTypeToBeUsedInQuery, PointsNodeTypeToBeUsedInQuery],
    };
  }
};

export function getShopQueryForSearchInput(
  searchObj: SearchQueryOptionsType,
  hiddenData: any,
  state: SearchStateStoreDataType,
  isFLexible?: boolean
): SearchQueryOptionsType {
  /*
  modify the search query object according to api shopLARSearchInput
  */
  const obj = Object.assign({}, searchObj.search);
  if (obj.distance) {
    delete obj.distance;
  }
  if (obj.options?.distance) {
    delete obj.options?.distance;
  }

  if (isFLexible) {
    // do some stuff if required
    // remove unused param from flexible api as these params are not required for
    const isGuest = obj?.options?.numberOfGuestRooms && Number(obj?.options?.numberOfGuestRooms) > 1;
    if (!isGuest) {
      delete obj?.options?.numberOfGuestRooms;
    }
    delete obj?.options?.quantity;
  } else {
    delete obj[SearchFacetsConstants.destination];
  }

  const searcObj: SearchOptionsType = {
    ...obj,
    [SearchFacetsConstants.facets]:
      getSearchAppliedFilter(getDestinationHiddenFilterData(hiddenData), state) ??
      getInitialSearchFacets(state[SearchFacetsConstants.points] ?? false),
  };
  searchObj[SearchFacetsConstants.search] = searcObj;
  return searchObj;
}
