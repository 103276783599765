export enum FLAG_TYPE {
  STRING = 'string',
  BOOLEAN = 'boolean',
  JSON = 'json',
}

export interface FeatureFlagConfig {
  [key: string]: {
    id: FEATURE_FLAGS;
    type: FLAG_TYPE.BOOLEAN | FLAG_TYPE.JSON | FLAG_TYPE.STRING;
  };
}

export enum FEATURE_FLAGS {
  PHOENIX_CORE_TEST_FLAG = 'phoenix_core_test_flag',
  PHOENIX_CORE_TEST_STRING = 'phoenix_core_test_string',
  PHOENIX_CORE_TEST_JSON = 'phoenix_core_test_json',
  EXPERIENCE_MCOM_BRAND_CSS = 'experience_mcom_brand_css',
  EXPERIENCE_MCOM_DTT_GROUP = 'experience_mcom_dtt_group',
  EXPERIENCE_MCOM_DTT_GROUP_UXL_ALLOW_DEFAULT_DATES = 'experience_mcom_dtt_group_uxl_allow_default_dates',
  EXPERIENCE_EXPMGMT_BOOK_DTT = 'experience_sandbox_book_dtt',
  EXPERIENCE_MCOM_BOOK_DTT_PROPERTIES_TYPE = 'experience_mcom_book_dtt_properties',
  EXPERIENCE_MCOM_MGP_BOOK = 'experience_mcom_mgp_book',
  EXPERIENCE_MCOM_MGP_BOOK_NRP = 'experience_mcom_book_dtt_book_nrp',
}

export const featureFlagDefinitions: FeatureFlagConfig = {
  [FEATURE_FLAGS.PHOENIX_CORE_TEST_FLAG]: {
    id: FEATURE_FLAGS.PHOENIX_CORE_TEST_FLAG,
    type: FLAG_TYPE.BOOLEAN,
  },
  [FEATURE_FLAGS.PHOENIX_CORE_TEST_STRING]: {
    id: FEATURE_FLAGS.PHOENIX_CORE_TEST_STRING,
    type: FLAG_TYPE.STRING,
  },
  [FEATURE_FLAGS.PHOENIX_CORE_TEST_JSON]: {
    id: FEATURE_FLAGS.PHOENIX_CORE_TEST_JSON,
    type: FLAG_TYPE.JSON,
  },
  [FEATURE_FLAGS.EXPERIENCE_MCOM_BRAND_CSS]: {
    id: FEATURE_FLAGS.EXPERIENCE_MCOM_BRAND_CSS,
    type: FLAG_TYPE.BOOLEAN,
  },
  [FEATURE_FLAGS.EXPERIENCE_MCOM_DTT_GROUP]: {
    id: FEATURE_FLAGS.EXPERIENCE_MCOM_DTT_GROUP,
    type: FLAG_TYPE.BOOLEAN,
  },
  [FEATURE_FLAGS.EXPERIENCE_MCOM_DTT_GROUP_UXL_ALLOW_DEFAULT_DATES]: {
    id: FEATURE_FLAGS.EXPERIENCE_MCOM_DTT_GROUP_UXL_ALLOW_DEFAULT_DATES,
    type: FLAG_TYPE.BOOLEAN,
  },
  [FEATURE_FLAGS.EXPERIENCE_EXPMGMT_BOOK_DTT]: {
    id: FEATURE_FLAGS.EXPERIENCE_EXPMGMT_BOOK_DTT,
    type: FLAG_TYPE.BOOLEAN,
  },
  [FEATURE_FLAGS.EXPERIENCE_MCOM_BOOK_DTT_PROPERTIES_TYPE]: {
    id: FEATURE_FLAGS.EXPERIENCE_MCOM_BOOK_DTT_PROPERTIES_TYPE,
    type: FLAG_TYPE.JSON,
  },
  [FEATURE_FLAGS.EXPERIENCE_MCOM_MGP_BOOK]: {
    id: FEATURE_FLAGS.EXPERIENCE_MCOM_MGP_BOOK,
    type: FLAG_TYPE.BOOLEAN,
  },
  [FEATURE_FLAGS.EXPERIENCE_MCOM_MGP_BOOK_NRP]: {
    id: FEATURE_FLAGS.EXPERIENCE_MCOM_MGP_BOOK_NRP,
    type: FLAG_TYPE.BOOLEAN,
  },
};

export const TARGETS = {
  APP: {
    CONTACTLESS_RENDERER: {
      identifier: 'mi-contactless-renderer',
      name: 'mi-contactless-renderer',
    },
    BOOK_RENDERER: {
      identifier: 'mi_book_renderer_mgp',
      name: 'mi-book-renderer-mgp',
    },
    GROUPS_RENDERER_DTT: {
      identifier: 'mi-groups-renderer-dtt',
      name: 'mi-groups-renderer-dtt',
    },
    BOOK_RENDERER_BAU: {
      identifier: 'mi_book_renderer_bau',
      name: 'mi-book-renderer-bau',
    },
  },
};
