import React, { createContext, useContext } from 'react';
import { hqvInitialStateType, HQV_ACTIONS } from '../../reducers';
import { propertySessionDataType } from '../SessionUpdateContext/index.types';

interface HqvDispatch {
  type: HQV_ACTIONS;
  payload: boolean | propertySessionDataType | string;
}

const mockSessionData = {
  cacheData: {
    data: {
      AriesSearch: {
        searchCriteria: {},
      },
      AriesCommon: {},
      AriesReservation: {},
      memberLevel: '',
      rewardsId: '',
      consumerID: '',
    },
  },
};

export const HQVContext = createContext<{
  hqvState: hqvInitialStateType;
  hqvDispatch: React.Dispatch<HqvDispatch>;
}>({
  hqvState: {
    amenetiesAvailable: false,
    informationAvailable: false,
    photosAvailable: false,
    propertyDetailsAbailable: false,
    propertyIDvalue: '',
    modalOpenState: false,
    propertySessionData: mockSessionData,
  },
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  hqvDispatch: () => {},
});

export const useHQVContext = () => {
  return useContext(HQVContext);
};
