import { StateCreator } from 'zustand';
import { createAppStore } from '@marriott/mi-store-utils';

type LocaleState = {
  locale: string;
  rtl: boolean;
};

type LocaleActions = {
  setLocale: (locale: string) => void;
  setRtl: (rtl: boolean) => void;
};

const initialState: LocaleState = {
  locale: '',
  rtl: false,
};

export const localeStore: StateCreator<LocaleState & LocaleActions> = set => {
  return {
    ...initialState,

    setLocale: (locale: string) => {
      set(prevState => {
        return {
          ...prevState,
          locale,
        };
      });
    },
    setRtl: (rtl: boolean) => {
      set(prevState => {
        return {
          ...prevState,
          rtl,
        };
      });
    },
  };
};

export const useLocaleStore = createAppStore(localeStore);
