import { StateCreator } from 'zustand';
import { createAppStore } from '@marriott/mi-store-utils';
import { FEATURE_FLAGS } from '@marriott/mi-feature-flag-utils';

type DttState = {
  isDtt: boolean;
  isDefaultDatesAllowedInDttUxlInput: boolean;
};

type DttActions = {
  setDttFlags: (dttFlags: { [key in FEATURE_FLAGS]?: string | boolean | Record<string, unknown> }) => void;
};

const initialState: DttState = {
  isDtt: false,
  isDefaultDatesAllowedInDttUxlInput: false,
};

export const dttStore: StateCreator<DttState & DttActions> = set => {
  return {
    ...initialState,

    setDttFlags: (dttFlags: { [key in FEATURE_FLAGS]?: string | boolean | Record<string, unknown> }) => {
      set(prevState => {
        const isDtt = !!dttFlags?.[FEATURE_FLAGS.EXPERIENCE_MCOM_DTT_GROUP];
        return {
          ...prevState,
          isDtt,
          isDefaultDatesAllowedInDttUxlInput:
            isDtt && !!dttFlags?.[FEATURE_FLAGS.EXPERIENCE_MCOM_DTT_GROUP_UXL_ALLOW_DEFAULT_DATES],
        };
      });
    },
  };
};

export const useDttStore = createAppStore(dttStore);
