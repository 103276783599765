import Cookies from 'js-cookie';
import { useClientEnvVarsStore } from '@marriott/mi-store-utils';

/* eslint-disable no-async-promise-executor */
export async function updateSession(data: Record<string, string>) {
  const clientVars = useClientEnvVarsStore.getState().envVarsObject;

  return new Promise(async (resolve, reject) => {
    if (process.env['IS_LOCAL_DEV'] === 'true') {
      resolve({ sessionHeader: '', sessionData: '' });
    } else {
      try {
        const response = await fetch(
          `${process.env['SESSION_APP_POST_CALL_URL'] || clientVars['SESSION_APP_POST_CALL_URL']}`,
          {
            method: 'POST',
            headers: {
              Cookie: Cookies.get('sessionID')?.toString() ?? '',
              'Access-Control-Allow-Origin': '*',
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
          }
        );

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const result = await response?.json();
        resolve({
          sessionHeader: response.headers,
          sessionData: result,
        });
      } catch (error) {
        reject('Update session error');
      }
    }
  });
}

export async function getSession() {
  const clientVars = useClientEnvVarsStore.getState().envVarsObject;

  return new Promise(async (resolve, reject) => {
    if (process.env['IS_LOCAL_DEV'] === 'true') {
      resolve({ sessionHeader: '', sessionData: '' });
    } else {
      try {
        const response = await fetch(
          `${process.env['SESSION_APP_GET_CALL_URL'] || clientVars['SESSION_APP_GET_CALL_URL']}`
        );

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const result = await response?.json();
        resolve({
          sessionHeader: response.headers,
          sessionData: result,
        });
      } catch (error) {
        reject('Get session error');
      }
    }
  });
}
