import { PropertyFacet, PropertySearchTermFacetBucket } from '@marriott/mi-groups-graphql';
import { DrawerLabels } from '../../molecules/Drawer/Drawer.types';
import { ViewHotelDetails } from '../../molecules/PropertyQuickViewModal/PropertyQuickViewModal.types';
import { PricingInfoLabels } from '../../molecules/PropertyCard/PropertyCard.types';

export enum ViewType {
  LIST_VIEW,
  MAP_VIEW,
  QUICK_VIEW,
}

export enum SearchType {
  NONE,
  GEOLOCATION,
  LOCATION_STATE,
  LOCATION_COUNTRY,
  DESTINATION,
}

export enum SearchFacets {
  AMENITIES = 'amenities',
  ACTIVITIES = 'activities',
  BRANDS = 'marriottBrands',
  CITIES = 'cities',
  STATES = 'states',
  COUNTRIES = 'countries',
  HOTEL_SERVICE_TYPES = 'hotelType',
  TRANSPORTATION_TYPES = 'transportation',
  MEETINGS_EVENTS = 'events',
  POINTS = 'points',
  PROPERTY_TYPES = 'propertyTypes',
  PRICE = 'price',
  DISTANCE = 'distance',
  LEISURE_REGIONS = 'leisure',
  ALL_FILTERS = 'allFilters',
}

export type SelectedFacets = Partial<
  Record<PropertyFacet, PropertySearchTermFacetBucket[]> & {
    [PropertyFacet.BRANDS]: BrandItem[];
  }
>;

export type FacetKey = keyof SelectedFacets;

type PillOption = { code: string; label: string };

export type FilterPills = {
  allFilters: PillOption;
  activities: PillOption;
  amenities: PillOption;
  brands: PillOption;
};

type SortOption = { code: string; label: string; selected: boolean };

export type SearchResultsProps = {
  property: Property;
  propertiesPanel: DrawerLabels;
  viewHotelDetails: ViewHotelDetails;
  pagination: Pagination;
  filters: SearchFilters;
  ctas: Ctas;
  error: {
    noResults: string;
    apiFailure: string;
  };
  analytics: {
    enableClickTracking: boolean;
  };
  styleclass: string;
};

export type Property = PricingInfoLabels & {
  carouselAriaLabel: string;
  carouselImageAriaLabel: string;
  logoAriaLabel: string;
  toAirport: string;
  reviews: string;
  meetingEventRooms: string;
  guestRooms: string;
  totalEventSpace: string;
  largestRoom: string;
  spaceUnit: string;
  restaurantOnSite: string;
  fitnessCenterOnSite: string;
  businessCenterOnSite: string;
  freeWifi: string;
  openingSoon: string;
  fullyRenovated: string;
  recentlyRenovated: string;
  renovatedRooms: string;
  newHotel: string;
  renovatedLobby: string;
  reviewTooltipText: string;
};

export type Pagination = {
  paginationDetails: string;
  previous: string;
  next: string;
  pageSize: string;
};

export type SortFilter = {
  options: SortOption[];
  sortBy: string;
};

export type SearchFilters = {
  pills: FilterPills;
  sortFilter: SortFilter;
  brandFilter: BrandFilter;
  availability: string;
  availabilityDescription: string;
  city: string;
  distance: string;
  events: string;
  statesProvinces: string;
  transportation: string;
  distanceMeasurementUnit: string;
  anyDistanceLabel: string;
  showMoreLabel: string;
  showLessLabel: string;
  pillsTrackDescription: string;
  pillsTrackLocaltion: string;
  freeWifi?: string;
};

type BrandFilter = {
  categorizedBrands: BrandCategory[];
};

export type BrandItem = { brandTagId: string; brandTagTitle: string; isDisabled?: boolean };

export type BrandCategory = {
  categoryTitle: string;
  categorySelectLabel: string;
  categoryClearLabel: string;
  brands: BrandItem[];
};

export type Ctas = {
  heading: string;
  viewHotelDetail: string;
  viewHotelDetailAriaLabel: string;
  bookNow: string;
  bookNowAriaLabel: string;
  bookNowURL: string;
  bookNowTrackLocation: string;
  bookNowTrackDescription: string;
  addToRfp: string;
  addToRfpAriaLabel: string;
  addToRfpTrackLocation: string;
  selected: string;
  selectedAriaLabel: string;
  cancelAriaLabel: string;
  clear: string;
  clearAriaLabel: string;
  apply: string;
  applyAriaLabel: string;
  createRfp: string;
  createRfpAriaLabel: string;
  createRfpTrackLocation: string;
  list: string;
  listAriaLabel: string;
  listTrackLocation: string;
  map: string;
  mapAriaLabel: string;
  mapTrackLocation: string;
};

export type QuickGroupData = {
  groupId: string;
  functionSpaceId: string;
  functionSpaceName: string;
  propertyId: string;
  brandId: string;
  startDate: string;
  endDate: string;
  eventType?: string;
  numberOfRooms: string;
  numberOfAttendees: string;
};
